@import url("https://p.typekit.net/p.css?s=1&k=qhq7wqh&ht=tk&f=10879.10884.10886.15586.15498.15505.15506.15507.15508.15509.15510&a=86632634&app=typekit&e=css");
@font-face {
    font-family: "futura-pt";
    src: url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
}
@font-face {
    font-family: "futura-pt";
    src: url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}
@font-face {
    font-family: "futura-pt";
    src: url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
}
@font-face {
    font-family: "futura-pt";
    src: url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/9d5bd3/00000000000000007735a09f/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/75987d/00000000000000007735a0a8/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/75987d/00000000000000007735a0a8/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/75987d/00000000000000007735a0a8/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
    font-display: fallback;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/dec5ec/00000000000000007735a096/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/dec5ec/00000000000000007735a096/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/dec5ec/00000000000000007735a096/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/eb6e64/00000000000000007735a0a0/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/eb6e64/00000000000000007735a0a0/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/eb6e64/00000000000000007735a0a0/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("opentype");
    font-display: fallback;
    font-style: italic;
    font-weight: 300;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/00cc08/00000000000000007735a0a6/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/71b9cd/00000000000000007735a0aa/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/71b9cd/00000000000000007735a0aa/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/71b9cd/00000000000000007735a0aa/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("opentype");
    font-display: fallback;
    font-style: italic;
    font-weight: 500;
    font-stretch: normal;
}
@font-face {
    font-family: "roboto";
    src: url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/7f1b26/00000000000000007735a0ac/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}
body {
    color: $river_bed;
    font-family: $roboto;
    font-size: $bodypx;
    line-height: $bodypx * 1.5;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: $futura_pt;
    font-weight: $light;
    a {
        font-weight: $light;
    }
}
h1 {
    text-align: center;
}
h1 {
    font-size: $h1px * 0.5;
    font-weight: $light;
    line-height: $h1px * 0.5;
    @media screen and (min-width: $screen-sm) {
        font-size: $h1px * 0.75;
        line-height: $h1px * 0.75;
    }
    @media screen and (min-width: $screen-md) {
        font-size: $h1px;
        line-height: $h1px;
    }
}
h2 {
    font-family: $roboto;
    font-size: $h2px;
    font-weight: $medium;
    line-height: $h2px * 1.5;
}
h3 {
    text-transform: uppercase;
    position: relative;
    font-size: $h3px;
    font-weight: $demi;
    line-height: $h3px * 1.5;
}
h4 {
    font-size: $h4px;
    line-height: $h4px * 1.5;
}
p,
ul {
    // margin: 30px 0 0;
}
a,
button,
.btn {
    cursor: pointer;
    text-decoration: none;
    transition: 300ms ease all;
    color: $mountain_meadow;
    outline: none !important;
}
a {
    font-weight: $book;
    &:hover {
        color: darken($mountain_meadow, 10%);
    }
}
.btn {
    @include flex;
    @include flex-align(center, middle);
    text-align: center;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    padding: 10px 20px;
    justify-self: flex-start;
    &.btn-white {
        background: $white;
        &.btn-inactive {
            &:hover {
                background: $white;
            }
        }
        &:hover {
            background: darken($white, 10%);
        }
    }
    &.btn-green {
        background: $mountain_meadow;
        color: $white;
        &.btn-inactive {
            &:hover {
                background: $mountain_meadow;
            }
        }
        &:hover {
            background: darken($mountain_meadow, 10%);
        }
        .fa {
            color: $white;
        }
        svg {
            fill: $white;
        }
    }
    &.btn-red {
        background: $guardsman_red;
        color: $white;
        &.btn-inactive {
            &:hover {
                background: $guardsman_red;
            }
        }
        &:hover {
            background: darken($guardsman_red, 10%);
        }
        .fa {
            color: $white;
        }
        svg {
            fill: $white;
        }
    }
    &.btn-inverse {
        background: $white;
        &.btn-inactive {
            &:hover {
                background: $white;
            }
        }
        &:hover {
            background: $mountain_meadow;
            color: $white;
        }
    }
    &.btn-text {
        background: none;
        color: $mountain_meadow;
        &.btn-inactive {
            &:hover {
                background: none;
            }
        }
        &:hover {
            color: darken($mountain_meadow, 10%);
        }
        .fa {
            color: $mountain_meadow;
        }
        svg {
            fill: $mountain_meadow;
        }
    }
    &.btn-large {
        font-size: 24px;
    }
    &.btn-inline {
        display: inline-block;
    }
    &.btn-inactive {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .fa,
    svg {
        margin-right: 5px;
    }
    &.btn-outline {
        border: 2px solid $mountain_meadow;
    }
}
.qtx-global-green {
    color: #13ab5b;
}
.qtx-global-green-highlight {
    background-color: #13ab5b;
}
.qtx-global-strikethrough {
    text-decoration: line-through;
}